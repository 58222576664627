<template>
  <style-component></style-component>
  <!-- Navigation-->
  <nav
    class="navbar navbar-expand-lg navbar-light fixed-top shadow-sm"
    id="mainNav"
  >
    <div class="container px-5">
      <a class="navbar-brand fw-bold" href="#page-top">The Luckiest Charm</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarResponsive"
        aria-controls="navbarResponsive"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        Navigation
        <i class="bi-list"></i>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ms-auto me-4 my-3 my-lg-0">
          <li class="nav-item">
            <a class="nav-link me-lg-3" href="#features">Information</a>
          </li>
          <li class="nav-item">
            <a class="nav-link me-lg-3" href="#saleOriginal"
              >Sale: Original Charm</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link me-lg-3" href="#saleCustom"
              >Sale: Custom Charm</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link me-lg-3" href="#proof">Proof of Policy ID</a>
          </li>
        </ul>

        <a
          class="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0"
          role="button"
          href="https://discord.gg/Snj95GGs"
        >
          <i class="bi-chat-text-fill me-2"></i>
          Chat with the author
        </a>
      </div>
    </div>
  </nav>

  <!-- Mashead header-->
  <header class="masthead">
    <div class="container px-5">
      <div class="row gx-5 align-items-center">
        <div class="col-lg-6">
          <!-- Mashead text and app badges-->
          <div class="mb-5 mb-lg-0 text-center text-lg-start">
            <h1 class="display-1 lh-1 mb-3">Get the luckiest CNFT charm!</h1>
            <p class="lead fw-normal text-muted mb-5">
              Policy ID: "8888 8888"
              <br />
              <br />
              This is not just any Neko,<br />
              this is the luckiest of them all!
              <br />
              <br />
              The number "8" is widely considered to be a lucky number and the
              policy ID of this charm starts with eight of these.
              <br />
              <br />
              This Neko has some fates to shift and it shows.
            </p>
          </div>
        </div>
        <div class="col-lg-6">
          <!-- Masthead device mockup feature-->
          <div class="masthead-device-mockup">
            <svg
              class="circle"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  id="circleGradient"
                  gradientTransform="rotate(45)"
                >
                  <stop class="gradient-start-color" offset="0%"></stop>
                  <stop class="gradient-end-color" offset="100%"></stop>
                </linearGradient>
              </defs>
              <circle cx="50" cy="50" r="50"></circle></svg
            ><svg
              class="shape-1 d-none d-sm-block"
              viewBox="0 0 240.83 240.83"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="-32.54"
                y="78.39"
                width="305.92"
                height="84.05"
                rx="42.03"
                transform="translate(120.42 -49.88) rotate(45)"
              ></rect>
              <rect
                x="-32.54"
                y="78.39"
                width="305.92"
                height="84.05"
                rx="42.03"
                transform="translate(-49.88 120.42) rotate(-45)"
              ></rect></svg
            ><svg
              class="shape-2 d-none d-sm-block"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="50" cy="50" r="50"></circle>
            </svg>
            <div class="device-wrapper">
              <div
                class="device"
                data-device="iPhoneX"
                data-orientation="portrait"
                data-color="black"
              >
                <div class="screen bg-black">
                  <!-- PUT CONTENTS HERE:-->
                  <!-- * * This can be a video, image, or just about anything else.-->
                  <!-- * * Set the max width of your media to 100% and the height to-->
                  <!-- * * 100% like the demo example below.-->
                  <video
                    muted="muted"
                    autoplay=""
                    loop=""
                    style="max-width: 100%; height: 100%"
                  >
                    <source
                      src="@/assets/vid/lucky_cat_final.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- App features section-->
  <section id="features">
    <div class="container px-5">
      <div class="row gx-5 align-items-center">
        <div class="col-lg-8 order-lg-1 mb-5 mb-lg-0">
          <div class="container-fluid px-5">
            <div class="row gx-5">
              <div class="col-md-6 mb-5">
                <!-- Feature item-->
                <div class="text-center">
                  <i
                    class="
                      bi bi-tags-fill
                      icon-feature
                      text-gradient
                      d-block
                      mb-3
                    "
                  ></i>
                  <h3 class="font-alt">Price</h3>
                  <p class="text-muted mb-0">
                    Original Charm: {{ originalSalePrice }} ₳
                    <br />
                    Customizable Charm: {{ customSalePrice }} ₳
                    <br />
                    <br />
                    Dust included, only one NFT per purchase.
                  </p>
                </div>
              </div>
              <div class="col-md-6 mb-5">
                <!-- Feature item-->
                <div class="text-center">
                  <i
                    :class="[
                      'bi-bullseye',
                      'icon-feature',
                      'text-gradient',
                      'd-block',
                      'mb-3',
                    ]"
                  ></i>
                  <h3 class="font-alt">Mintage</h3>
                  <p class="text-muted mb-0">
                    Original Charm: 88
                    <br />
                    Customizable Charm: 88
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 mb-5 mb-md-0">
                <!-- Feature item-->
                <div class="text-center">
                  <i
                    class="
                      bi-clock-fill
                      icon-feature
                      text-gradient
                      d-block
                      mb-3
                    "
                  ></i>
                  <h3 class="font-alt">Availability</h3>
                  <p class="text-muted mb-0">
                    Start: 08.08.2021 08:00 GMT
                    <br />
                    End: 12.08.2021 00:00 GMT
                    <br />
                    Duration: 88 hours
                  </p>
                </div>
              </div>
              <div class="col-md-6">
                <!-- Feature item-->
                <div class="text-center">
                  <i
                    class="
                      bi-droplet-fill
                      icon-feature
                      text-gradient
                      d-block
                      mb-3
                    "
                  ></i>
                  <h3 class="font-alt">Customize your Charm!</h3>
                  <p class="text-muted mb-0">
                    Prefer to have a different image or video in your lucky
                    charm?
                    <br />
                    Maybe something personal?
                    <br />
                    <br />
                    Our customizable Charm is just that! Chat with the author to
                    get your content with the ID "8888 8888"
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 order-lg-0">
          <!-- Features section device mockup-->
          <div class="features-device-mockup">
            <svg
              class="circle"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs>
                <linearGradient
                  id="circleGradient"
                  gradientTransform="rotate(45)"
                >
                  <stop class="gradient-start-color" offset="0%"></stop>
                  <stop class="gradient-end-color" offset="100%"></stop>
                </linearGradient>
              </defs>
              <circle cx="50" cy="50" r="50"></circle></svg
            ><svg
              class="shape-1 d-none d-sm-block"
              viewBox="0 0 240.83 240.83"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="-32.54"
                y="78.39"
                width="305.92"
                height="84.05"
                rx="42.03"
                transform="translate(120.42 -49.88) rotate(45)"
              ></rect>
              <rect
                x="-32.54"
                y="78.39"
                width="305.92"
                height="84.05"
                rx="42.03"
                transform="translate(-49.88 120.42) rotate(-45)"
              ></rect></svg
            ><svg
              class="shape-2 d-none d-sm-block"
              viewBox="0 0 100 100"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="50" cy="50" r="50"></circle>
            </svg>
            <div class="device-wrapper">
              <div
                class="device"
                data-device="iPhoneX"
                data-orientation="portrait"
                data-color="black"
              >
                <div class="screen bg-black">
                  <!-- PUT CONTENTS HERE:-->
                  <!-- * * This can be a video, image, or just about anything else.-->
                  <!-- * * Set the max width of your media to 100% and the height to-->
                  <!-- * * 100% like the demo example below.-->
                  <video
                    muted="muted"
                    autoplay=""
                    loop=""
                    style="max-width: 100%; height: 100%"
                  >
                    <source src="@/assets/vid/custom.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-light" id="saleOriginal">
    <div class="container px-5">
      <div
        class="
          row
          gx-5
          align-items-center
          justify-content-center justify-content-lg-between
        "
      >
        <div class="col-12 col-lg-5">
          <h2 class="display-4 lh-1 mb-4">Original Charm</h2>
          <p class="lead fw-normal text-muted mb-5 mb-lg-0">
            Nothing ever comes close to the original.
            <br />
            <br />
            This is <u>the</u> original Vanity ID CNFT and shall bring luck to
            it's owners wallet.
          </p>
        </div>
        <div class="col-sm-8 col-md-6">
          <div class="px-5 px-sm-0">
            <div
              class="bg-gradient-4 shadow p-5 text-center mb-5"
              v-if="originalSaleStarted === false"
            >
              <p class="mb-0 font-weight-bold text-uppercase">Sale opens in:</p>
              <div id="clock-c" class="countdown py-4">
                <vue-countdown
                  :time="originalSaleStart - new Date()"
                  v-slot="{ days, hours, minutes, seconds }"
                  @end="originalSaleStarted = true"
                >
                  <span>{{ days }}</span> Days <span>{{ hours }}</span> Hours
                  <span>{{ minutes }}</span> Minutes
                  <span>{{ seconds }}</span> Seconds
                </vue-countdown>
              </div>
            </div>
            <div
              class="bg-gradient-4 shadow p-5 text-center mb-5"
              v-else-if="originalSaleOver === false"
            >
              <p class="mb-0 font-weight-bold text-uppercase">
                Sale time remaining:
              </p>
              <div id="clock-c" class="countdown py-4">
                <vue-countdown
                  :time="originalSaleEnd - new Date()"
                  v-slot="{ days, hours, minutes, seconds }"
                  @end="originalSaleOver = true"
                >
                  <span>{{ days }}</span> Days <span>{{ hours }}</span> Hours
                  <span>{{ minutes }}</span> Minutes
                  <span>{{ seconds }}</span> Seconds
                </vue-countdown>
                <br />
                <br />
                Send <u>exactly</u> {{ originalSalePrice }} ADA to this address:
                <br />
                <br />
                <div class="card">
                  <div class="card-body">
                    <span class="preformatted" id="originalSaleAddr">{{
                      originalSaleAddr
                    }}</span>

                    <button
                      @click="copyOriginalSaleAddr"
                      data-bs-toggle="popover"
                      title="Popover title"
                      type="button"
                      class="btn btn-light"
                    >
                      <i
                        :class="[
                          originalClipboardCopied
                            ? 'bi-check2-all'
                            : 'bi-files',
                          'text-gradient',
                        ]"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gradient-4 shadow p-5 text-center mb-5" v-else>
              <p class="mb-0 font-weight-bold text-uppercase">Sale is over!</p>
              <div id="clock-c" class="countdown py-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="bg-light" id="saleCustom">
    <div class="container px-5">
      <div
        class="
          row
          gx-5
          align-items-center
          justify-content-center justify-content-lg-between
        "
      >
        <div class="col-12 col-lg-5">
          <h2 class="display-4 lh-1 mb-4">Custom Charm</h2>
          <p class="lead fw-normal text-muted mb-5 mb-lg-0">
            The custom Charm is perfect if you want to take advantage of this
            one-in-4.3-billion odds with your personalized NFT.
            <br />
            <br />
            Any Metadata entries, any media linked to it, everything can be
            customized until the policy locks on the 31st of August.
          </p>
        </div>
        <div class="col-sm-8 col-md-6">
          <div class="px-5 px-sm-0">
            <div
              class="bg-gradient-4 shadow p-5 text-center mb-5"
              v-if="customSaleStarted === false"
            >
              <p class="mb-0 font-weight-bold text-uppercase">Sale opens in:</p>
              <div id="clock-c" class="countdown py-4">
                <vue-countdown
                  :time="customSaleStart - new Date()"
                  v-slot="{ days, hours, minutes, seconds }"
                  @end="customSaleStarted = true"
                >
                  <span>{{ days }}</span> Days <span>{{ hours }}</span> Hours
                  <span>{{ minutes }}</span> Minutes
                  <span>{{ seconds }}</span> Seconds
                </vue-countdown>
              </div>
            </div>
            <div
              class="bg-gradient-4 shadow p-5 text-center mb-5"
              v-else-if="customSaleOver === false"
            >
              <p class="mb-0 font-weight-bold text-uppercase">
                Sale time remaining:
              </p>
              <div id="clock-c" class="countdown py-4">
                <vue-countdown
                  :time="customSaleEnd - new Date()"
                  v-slot="{ days, hours, minutes, seconds }"
                  @end="customSaleOver = true"
                >
                  <span>{{ days }}</span> Days <span>{{ hours }}</span> Hours
                  <span>{{ minutes }}</span> Minutes
                  <span>{{ seconds }}</span> Seconds
                </vue-countdown>
                <br />
                <br />
                Send <u>exactly</u> {{ customSalePrice }} ADA to this address:
                <br />
                <br />
                <div class="card">
                  <div class="card-body">
                    <span class="preformatted" id="customSaleAddr">{{
                      customSaleAddr
                    }}</span>

                    <button
                      @click="copyCustomSaleAddr"
                      data-bs-toggle="popover"
                      title="Popover title"
                      type="button"
                      class="btn btn-light"
                    >
                      <i
                        :class="[
                          customClipboardCopied ? 'bi-check2-all' : 'bi-files',
                          'text-gradient',
                        ]"
                      ></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gradient-4 shadow p-5 text-center mb-5" v-else>
              <p class="mb-0 font-weight-bold text-uppercase">Sale is over!</p>
              <div id="clock-c" class="countdown py-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Basic features section-->
        <section class="bg-light">
            <div class="container px-5">
                <div class="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div class="col-sm-8 col-md-6">
                        <div class="px-5 px-sm-0"><img class="img-fluid rounded-circle" src="@/assets/img/brushes.jpg" alt="..." /></div>
                    </div>
                    <div class="col-12 col-lg-5">
                        <h2 class="display-4 lh-1 mb-4">Your Token<br />Your Choice</h2>
                        <p class="lead fw-normal text-muted mb-5 mb-lg-0">The customizable charm is sold as a token and will instantly be minted.
                          You will have free choice about the metadata, artwork and title and can request any change until the policy locks on the 31st of August.
                          <br /><br />
                          How to customize the NFT:
                          <ul>
                            <li>
                              Get in touch with the author on Discord
                            </li>
                            <li>
                              Send them the art you want to use
                            </li>
                            <li>
                              Describe your wishes for the metadata and title<br />(No technical knowledge required)
                              </li>
                              <li>
                                Verify the ownership of the token 
                                </li>
                                <li>
                                  Let the team work their magic
                                  </li>
                            </ul>
                            The NFT will never need to leave your wallet!
                        </p>
                    </div>
                </div>
            </div>
        </section>
  <!-- Basic features section-->
  <section class="bg-light">
    <div class="container px-5">
      <div
        class="
          row
          gx-5
          align-items-center
          justify-content-center justify-content-lg-between
        "
      >
        <div class="col-12 col-lg-5">
          <h2 class="display-4 lh-1 mb-4">The perfect ID</h2>
          <p class="lead fw-normal text-muted mb-5 mb-lg-0">
            This NFT is the first publicly available NFT with a vanity ID.
            Nothing comes close to the perfection of having all eight visible
            characters set to "8".
            <br />
            <br />
            Only one in 4.3 billion policies will have the ID "8888 8888".
          </p>
        </div>
        <div class="col-sm-8 col-md-6">
          <div class="px-5 px-sm-0">
            <img class="img-fluid" src="@/assets/img/proof.png" alt="..." />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Call to action section-->
  <section class="cta" id="proof">
    <div class="cta-content">
      <div class="container px-5">
        <h2 class="text-white display-1 lh-1 mb-4">
          Trust is good.
          <br />
          Proof is better.
        </h2>
        <a
          class="btn btn-outline-light py-3 px-4 rounded-pill"
          href="https://pool.pm/888888882377a17299ab81efee67606bbb60a720ef345618f8b6b87d.PROOF"
          target="_blank"
          >See the proof!</a
        >
      </div>
    </div>
  </section>
  <!-- Footer-->
  <footer class="bg-black text-center py-5">
    <div class="container px-5">
      <div class="text-white-50 small">
        <div class="mb-2">
          &copy; 88888888-id.com 2021. All Rights Reserved.
        </div>
      </div>
    </div>
  </footer>
  <!-- Feedback Modal-->
  <div
    class="modal fade"
    id="feedbackModal"
    tabindex="-1"
    aria-labelledby="feedbackModalLabel"
    aria-hidden="true"
  ></div>
</template>

<script>
import StyleComponent from "./components/StyleComponent.vue";
export default {
  components: { StyleComponent },
  name: "App",
  data() {
    return {
      timeLeftCustom: { days: 0, hours: 0, minutes: 0, seconds: 0 },
      customSaleStart: new Date("2021-08-08T08:00:00Z"),
      customSaleEnd: new Date("2021-08-12T00:00:00Z"),
      customSaleTimerLoaded: false,
      customSaleOver: false,
      customSaleStarted: false,
      customSalePrice: 188,
      customSaleAddr: "addr1qy8j8jd750vj0x5acemqsnhnnpfvrpmgw3a9nv62frfetn4jaxmu8g8jkycyfjmg42gdv3m94pwl434xv98ep7utd4wsup3nl3",
      customClipboardCopied: false,
      timeLeftOriginal: { days: 0, hours: 0, minutes: 0, seconds: 0 },
      originalSaleStart: new Date("2021-08-08T08:00:00Z"),
      originalSaleEnd: new Date("2021-08-12T00:00:00Z"),
      originalSaleTimerLoaded: false,
      originalSaleOver: false,
      originalSaleStarted: false,
      originalSalePrice: 88,
      originalSaleAddr: "addr1qy9dt4hezdhl4j2rltyvxexdhvgnf5ugmez7l8fhmlss6xgtyz7dwzyjvxksjw8mt2r5mjduz4jc8tvffkuwdllnj9us23xmvm",
      originalClipboardCopied: false,
    };
  },
  watch: {},
  methods: {
    copyCustomSaleAddr() {
      navigator.clipboard.writeText(this.customSaleAddr);
      this.customClipboardCopied = true;
    },
    copyOriginalSaleAddr() {
      navigator.clipboard.writeText(this.originalSaleAddr);
      this.originalClipboardCopied = true;
    },
  },
  mounted() {
    if (new Date() > this.customSaleStart) {
      this.customSaleStarted = true;
    }
    if (new Date() > this.originalSaleStart) {
      this.originalSaleStarted = true;
    }
  },
};
</script>

<style>
@import "~bootstrap/dist/css/bootstrap.css";
</style>